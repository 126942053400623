:root {
  --tx-color1: #840011;
  --tx-color2: #1d1d1d;
  --tx-color3: #e8e8e8;
  --tx-color4: #6a6a6a;
  --btn-color1: #ffce29;
  --btn-color2: #a9a9a9;
  --speical-tx: #a01233;
}

.App {
  text-align: center;
}

.title1 {
  color: #8f1133;
  font-size: 50px;
  font-family: Poppins-Medium;
}

.title2 {
  font-size: 30px;
  font-family: Poppins-Regular;
  color: var(--tx-color2);
}

.title3 {
  font-size: 50px;
  font-family: Poppins-Medium;
  color: var(--tx-color2);
  padding: 70px;
}

.normal {
  font-size: 18px;
  font-family: Poppins-Regular;
  color: var(--tx-color2);
}

.section-padding {
  padding-top: 25px;
  padding-bottom: 75px;
}

.titlePadding {
  padding: 10px 0px 10px 0px;
}

iframe {
  border-radius: 15px;
}

.transcript-wrapper {
  border: 1.5px solid #707070;
  border-radius: 20px;
  opacity: 1;
  padding: 8px;
  width: 95%;
  height: 120px;
  overflow-y: hidden;
}

.transcript-text {
  font-family: Poppins-Regular;
  color: #6a6a6a;
  border-color: #6a6a6a;
  white-space: pre-wrap;
  outline: none;
  padding: 10px;
  height: inherit;
  overflow-y: auto;
  font-size: 16px;
}

.transcript-text:hover::-webkit-scrollbar-thumb {
  border-radius: 20px;
  overflow-y: scroll;
  background: #6a6a6a;
  height: 40px;
}

.transcript-text p {
  margin: 0;
}

.transcript-text::-webkit-scrollbar {
  width: 10px;
}

.transcript-text::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.toggle-button {
  text-align: center;
}
.toggle-button button {
  background-color: #ffce29;
  border: none;
  height: 50px;
  width: 250px;
  border-radius: 25px;
  font-family: Poppins-Medium;
  color: var(--tx-color2);
  text-transform: capitalize;
}

textarea::-webkit-input-placeholder {
  font-family: Poppins-Regular;
  font-size: 15px;
}
textarea::-moz-placeholder {
  font-family: Poppins-Regular;
  font-size: 15px;
}

textarea:-ms-input-placeholder {
  font-family: Poppins-Regular;
  font-size: 15px;
}

textarea:-moz-placeholder {
  font-family: Poppins-Regular;
  font-size: 15px;
}

/*Get rid placceholder when input and textarea focus*/
input:focus::-webkit-input-placeholder {
  opacity: 0;
}
input:focus::-moz-placeholder {
  opacity: 0;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
}

input:focus:-moz-placeholder {
  opacity: 0;
}

textarea:focus::-webkit-input-placeholder {
  opacity: 0;
}
textarea:focus::-moz-placeholder {
  opacity: 0;
}

textarea:focus:-ms-input-placeholder {
  opacity: 0;
}

textarea:focus:-moz-placeholder {
  opacity: 0;
}

/*header*/

.header {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("images/header.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-title,
.header-slogan {
  color: #ffffff;
  padding: 10px;
  max-width: 100vw;
}

#header-logo {
  width: 200px;
  height: 200px;
}

.header-title {
  font-size: 60px;
  font-family: Poppins-Medium;
}

.header-slogan {
  font-family: Poppins-Regular;
  font-size: 40px;
}

.header-bg {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
}

/*Navbar*/

.navbar {
  position: fixed;
  top: 0;
  color: #fefeff;
  font-family: Poppins-Medium;
  font-size: 15px;
  padding: 13px 0 13px 0;
  /* background: linear-gradient(#000 30%, #0000); */
  width: 100vw;
  justify-content: flex-end;
  z-index: 1;
}

.scrolled {
  background: linear-gradient(90deg, #bc1332, #220b36);
  color: #fff;
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

.scrolled-text {
  color: #fff;
}

.navbar a,
.navbar a:hover,
.navbar a:active {
  text-decoration: none;
}

.navbar-home {
  color: #fefeff;
  border-radius: 25px;
  white-space: pre;
  width: 120px;
  transition: background-color 1s;
}

.navbar-home:hover {
  color: var(--tx-color1);
  background-color: #fefeff;
}

.navbar-mizaru {
  color: #fefeff;
  border-radius: 25px;
  white-space: pre;
  width: 150px;
  transition: background-color 1s;
}

.navbar-mizaru:hover {
  color: var(--tx-color1);
  background-color: #fefeff;
}

.navbar-channel {
  color: #fefeff;
  transition: background-color 1s;
  white-space: pre;
  border-radius: inherit;
  border-radius: 25px;
}

.navbar-logo {
  position: relative;
  left: 0;
  display: none;
}

.navbar-logo img {
  width: 50px;
  height: 50px;
}

.navbar-channel:hover {
  background-color: #fefeff;
  text-decoration: none;
  color: var(--tx-color1);
  border-radius: 25px;
}

.navbar-dropdown {
  position: relative;
  margin-right: 50px;
  width: 1.5rem;
}

.navbar-dropdown-hamburger:hover {
  background-color: #fefeff;
  text-decoration: none;
  color: var(--tx-color1);
}

.navbar-divider {
  padding-left: 15px;
  padding-right: 15px;
}

.navbar-dropdown-content {
  position: absolute;
  right: 0;
}

.navbar-dropdown-hamburger {
  cursor: pointer;
  right: 10px;
  transition: background-color 1s;
}

.navbar-dropdown-content {
  display: none;
}

.navbar-dropdown-content .collapse {
  display: none;
}

.navbar-dropdown-content a {
  text-decoration: none;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: beige;
  width: 150px;
  display: block;
  z-index: 1;
  color: #710f34;
  background-color: white;
}

.navbar-dropdown-content a:hover {
  background-color: #710f34;
  color: #fff;
}

/*Intro*/

.intro {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.intro-content {
  text-align: left;
}

.intro-content,
.intro-video {
  padding: 0px 50px 0px 50px;
}

.intro-content-text .title2 {
  padding-top: 10px;
}

.intro-content-text,
.intro-video-text {
  padding-bottom: 10px;
}

.intro-video {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro-video iframe {
  width: 480px;
  height: 275px;
  max-width: 100%;
}

.intro-video-text {
  width: 480px;
  max-width: 100%;
  text-align: left;
  display: flex;
  justify-content: center;
}

/*Serivces*/
.service {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-video iframe {
  width: 600px;
  height: 400px;
}

.services-content {
  padding: 10px 0px 10px 0px;
}

.services-content-icon {
  padding-right: 10px;
  text-align: right;
}

.services-content-icon--img {
  width: 140px;
  height: 140px;
}

.services-content-text {
  max-width: 75%;
  padding: 0px 5px 0px 5px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.row service-content {
  padding: 20px 0 20px 0;
}

/*Team*/

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-main {
  width: 75%;
  padding: 20px 0px 20px 0px;
}

.team-main .row {
  padding: 10px 0px 10px 0px;
}

.team-main-profile {
  width: 180px;
  height: 180px;
  border-radius: 100px;
}

.team-main-name {
  font-family: Poppins-Regular;
  font-size: 28px;
  color: var(--tx-color2);
  padding: 15px 15px 0 15px;
}

.team-main-intro {
  font-family: Poppins-Regular;
  font-size: 20px;
  color: var(--tx-color2);
  padding: 0 0 20px 0;
}

.team-dev-wrapper {
  display: inline-flex;
  width: 20%;
  flex-direction: column;
  align-items: center;
}

.team-dev-profile {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.team-special {
  padding: 15px 0px 15px 0px;
  font-size: 30px;
  font-family: Poppins-Regular;
  color: var(--tx-color2);
}

.team-dev-name {
  font-family: Poppins-Medium;
  color: var(--tx-color2);
  padding-top: 15px;
}

.team-dev-intro {
  font-family: Poppins-Regular;
  color: #7c7c7c;
}

/*Service & Team*/

.services-video,
.team-video {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-video iframe {
  max-width: 100%;
  width: 640px;
  height: 360px;
}
.team-video iframe {
  max-width: 90%;
  width: 640px;
  height: 360px;
}
.services-video-text {
  width: 640px;
  max-width: 100%;
  text-align: left;
  display: flex;
  justify-content: center;
}

.team-video-text {
  width: 640px;
  max-width: 90%;
  text-align: left;
  display: flex;
  justify-content: center;
}

/*Partnerhsip*/

.partner-title {
  padding: 5px 0px 5px 0px;
}

.partner-wrapper {
  display: inline-flex;
  width: 20%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

.partner-name {
  font-family: Poppins-Regular;
  color: var(--tx-color2);
}

.partner-wrapper img {
  width: 150px;
}

.partner-list-showmore {
  background-color: #ffce29;
  border: none;
  height: 50px;
  width: 250px;
  border-radius: 25px;
  font-family: Poppins-Medium;
  color: var(--tx-color2);
}

/*Contact*/

.contact {
  background-color: var(--tx-color3);
}

.contact .row {
  padding: 5px 0px 15px 0px;
}

.contact .container {
  max-width: 80vw;
  padding: 20px 0px 30px 0px;
  /*border-bottom: 1px solid grey;*/
}

.contact-label {
  padding-right: 5px;
  display: flex;
  flex-direction: row-reverse;
  /*align-items: center;*/
  top: 18px;
  font-family: Poppins-Regular;
  color: #3a3a3a;
}

.contact-label-required {
  color: #a01233;
}

.contact-input {
  text-align: left;
}

.contact-input input {
  width: 50%;
  border-radius: 50px;
  padding: 15px 10px 15px 10px;
  border: none;
  outline: none;
  font-family: Poppins-Regular;
  font-size: 15px;
}

.contact-input textarea {
  width: 50%;
  border-radius: 10px;
  padding: 10px;
  border: none;
  outline: none;
  font-family: Poppins-Regular;
  font-size: 15px;
}

.contact-button,
.contact-button:hover,
.contact-button:active,
.contact-button:visited {
  background-color: #ffce29;
  border: none;
  height: 50px;
  width: 250px;
  border-radius: 25px;
  font-family: Poppins-Medium;
  color: var(--tx-color2);
}

.contact-submit {
  padding-top: 20px;
}
/*Footer*/

.footer {
  padding-top: 5vh;
  min-height: 35vh;
  background-color: var(--tx-color3);
}
.footer .container {
  border-top: 1px solid grey;
}

.footer-link1 span {
  padding: 15px;
}

.footer-link1 {
  font-family: Poppins-Regular;
  color: #3a3a3a;
  padding-top: 35px !important;
}
.footer-link1 a,
.footer-link1 a:hover {
  color: #3a3a3a;
}

.footer-link2 {
  font-family: Poppins-Medium;
  color: #3a3a3a;
}

.footer-link1,
.footer-link2 {
  padding: 10px;
}

.footer-icons {
  text-align: center;
  font-size: 30px;
}

.footer-icons i {
  cursor: pointer;
  margin: 0px 15px 30px 15px;
}

.footer-icons a {
  text-decoration: none;
  color: #3a3a3a;
  padding: 0px 10px 0px 10px;
}

.footer-icons-text {
  display: none;
}

.copyright {
  font-family: Poppins-Regular;
  color: #3a3a3a;
}

/*Privacy & Terms*/

.privacy,
.terms {
  margin-bottom: 50px;
}

.privacy-title,
.terms-title {
  padding: 0;
  margin-top: -40px;
  padding-top: 80px;
  padding-bottom: 40px;
}

.privacy-content,
.terms-content {
  text-align: left;
  width: 70%;
  margin: 0 auto;
}

.privacy-video,
.terms-video {
  padding: 0px 50px 0px 50px;
}
.contentWrapper {
  /*margin-top: 50px;*/
}

.contentWrapper .title2 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.contentWrapper .normal {
  padding-top: 5px;
  padding-bottom: 5px;
}
a {
  color: #003b9a;
}

.contentWrapper ul {
  padding-top: 10px;
  padding-bottom: 10px;
}

.privacy-video,
.terms-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 450px;
}

.privacy-video iframe,
.terms-video iframe {
  width: 480px;
  height: 275px;
}

.privacy-video-text,
.terms-video-text {
  width: 480px;
  text-align: left;
  display: flex;
  justify-content: center;
}

.go-back {
  text-align: center;
  margin: 40px 0 40px 0;
}
.go-back button {
  background-color: #ffce29;
  border: none;
  height: 50px;
  width: 250px;
  border-radius: 100px;
  font-family: Poppins-Medium;
  color: var(--tx-color2);
  text-transform: capitalize;
}

/*topbutton*/

.topbutton-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: grey;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.topbutton {
  width: 40px;
  height: 40px;
  border: 2px solid;
  border-color: #fff;
  color: #fff;
  background-color: grey;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 40px;
}

/*Safari Reminder*/
.bold-text {
  font-weight: bold;
}
.safarireminder {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins-Regular;
}

.safarireminder__close {
  position: absolute;
  top: 0px;
  right: 10px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.safarireminder__content {
  position: relative;
  color: #fff;
  background-color: var(--tx-color1);
  width: 600px;
  height: 300px;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  border-radius: 10px;
  padding: 0 25px 0 25px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
  animation-name: animatetop;
  animation-duration: 0.5s;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 0.9;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 0.9;
  }
}

.safarireminder__content__title {
  font-size: 25px;
  padding: 5px 0 5px 0;
}
.safarireminder__content__logo img {
  height: 120px;
  width: 120px;
}
.safarireminder__content__button {
  margin: 20px;
  background-color: #ffce29;
  border: none;
  height: 40px;
  width: 200px;
  border-radius: 25px;
  font-family: Poppins-Medium;
  color: var(--tx-color2);
}

/*font*/

@font-face {
  font-family: "Poppins-Medium";
  src: url("assets/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("assets/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("assets/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("assets/Poppins-SemiBold.ttf");
}

.see-more {
  color: #003b9a !important;
  cursor: pointer;
}

/*
* Tablet CSS
*/
@media screen and (max-width: 1024px) {
  .title1 {
    font-size: 40px;
  }

  .title2 {
    font-size: 24px;
  }

  .normal {
    font-size: 15px;
  }
  .section-padding {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  /*header*/
  .header-title {
    font-size: 40px;
  }
  .header-slogan {
    font-size: 30px;
  }

  /*intro*/
  .intro {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .intro-content,
  .intro-video {
    padding: 15px;
  }
  .privacy-content,
  .terms-content {
    width: 90%;
  }
  .privacy-content,
  .privacy-video,
  .terms-content,
  .temrs-video {
    padding-left: 15px;
    padding-right: 15px;
  }

  .intro-video-text,
  .privacy-video-text,
  .terms-video-text {
    width: 320px;
  }

  .intro-video iframe,
  .privacy-video iframe,
  .terms-video iframe {
    width: 320px;
    height: 200px;
  }

  /*Serivces*/
  .services-content-icon--img {
    width: 100px;
    height: 100px;
  }

  .services-content-icon {
    padding: 0;
    text-align: center;
  }

  /*Team*/
  .team-main {
    width: 100%;
  }

  .team-main-name {
    font-size: 21px;
  }
  .team-main-intro {
    font-size: 15px;
  }

  .team-main-profile {
    width: 150px;
    height: 150px;
  }

  .team-dev-intro {
    display: none;
  }

  .team-dev-wrapper {
    padding: 5px 0px 5px 0px;
    width: 33%;
  }

  /*Service & Team*/
  .services-video,
  .team-video {
    padding: 15px;
  }
  .services-video-text,
  .team-video-text {
    width: 80vw;
  }

  /*Partner*/
  .partner-wrapper {
    width: 33%;
  }

  /*Contact*/
  .contact-input input {
    width: 100%;
  }
  .contact-input textarea {
    width: 100%;
  }
  /*Footer*/
  .footer {
    min-height: 40vh;
  }

  /*Privacy & Terms*/
  .privacyPage,
  .termsPage {
    min-height: 60vh;
    font-size: 35px;
  }
}

/*
* Mobile CSS
*/
@media screen and (max-width: 767px) {
  /*iframe*/
  .intro-video iframe,
  .services-video iframe,
  .team-video iframe {
    width: 400px;
    height: 225px;
  }

  .intro-video-text,
  .team-video-text,
  .services-video-text {
    width: 400px;
    padding: 0;
  }

  /*Navbar*/
  .navbar-dropdown-content .collapse {
    display: block;
  }
  .navbar-dropdown-hamburger {
    width: 50px;
    font-size: 20px;
  }
  .navbar-home,
  .navbar-mizaru,
  .navbar-channel,
  .navbar-divider {
    display: none;
  }

  /*Intro*/
  .intro-content .title1 {
    text-align: center;
  }
  .intro-content {
    padding-left: 50px;
    padding-right: 50px;
  }

  /*Navbar*/
  .navbar {
    font-size: 15px;
  }

  /*team*/
  .team-dev-profile {
    width: 100px;
    height: 100px;
  }

  /*partner*/
  .partner-wrapper img {
    width: 100px;
  }
}

/*skip nav*/
.skiptocontent a {
  padding: 6px;
  position: absolute;
  top: -40px;
  left: 0px;
  color: white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  background: #0f0f0f;
  transition: top 0.8s ease-out;
}

.skiptocontent a:focus {
  top: 0px;
  transition: top 0.8s ease 0;
}

/*link button*/

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: #003b9a;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

@media screen and (-ms-high-contrast: active) {
  .header,
  .navbar {
    filter: invert(0%);
  }
}
@media screen and (-ms-high-contrast: black-on-white) {
  .header,
  .navbar {
    filter: invert(0%);
  }
}
@media screen and (-ms-high-contrast: white-on-black) {
  .header,
  .navbar {
    filter: invert(0%);
  }
}
